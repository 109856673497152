<template>
    <div class="user-thumbnail">
        <img src="@/assets/default/user-1.png" alt="">
    </div>
</template>

<style scoped>
.user-thumbnail {
    height: 40px;
    width: 40px;
    border-radius: 50% 50%;
    overflow: hidden;
}

.user-thumbnail > img {
    width: 100%;
    height: 100%;
}
</style>