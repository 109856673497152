<script setup>

</script>

<template>
    <div class="sidebar-one">
        <router-link to="/dashboard"><div :class="($route.name === 'dashboardPage') ? 'active' : ''"><font-awesome-icon :icon="['fa', 'home']" />  Home</div></router-link>
        <router-link to="/dashboard/about"><div :class="($route.name === 'aboutPage') ? 'active' : ''"><font-awesome-icon :icon="['fa', 'copy']" /> About</div></router-link>
        <router-link to="/category/list"><div :class="($route.name === 'categoryListPage') ? 'active' : ''"><font-awesome-icon :icon="['fa', 'copy']" /> Category</div></router-link>
        <router-link to="/work/list"><div :class="($route.name === 'groupPage') ? 'active' : ''"><font-awesome-icon :icon="['fa', 'users']" /> Work</div></router-link>
        <router-link to="/dashboard/testionial"><div :class="($route.name === 'testimonialPage') ? 'active' : ''"><font-awesome-icon :icon="['fa', 'users']" /> Testimonial</div></router-link>
        <router-link to="/dashboard/contact"><div :class="($route.name === 'testimonialPage') ? 'active' : ''"><font-awesome-icon :icon="['fa', 'users']" /> Contact</div></router-link>
    </div>
</template>

<style scoped>
.sidebar-one {
    padding: 20px;
    overflow: auto;
    border-right: 1px solid #ebebeb;
    display: block !important;
}

.sidebar-one > * > div {
    border-radius: 5px;
    padding: 10px 20px;
    display: flex;
    gap: 10px;
}

.sidebar-one > * {
    text-decoration: none;
    color: black;
}

.sidebar-one > * > div:hover {
    background-color: #ebebeb;
}

.active {
    background-color: #ebebeb;
}
</style>