<template>
  <header class="header">
    <div class="container header-container">
      <!-- Logo -->
      <router-link to="/" class="logo">
          Jahir Rayhan
      </router-link>

      <!-- Navigation Links (hidden on smaller screens) -->
      <nav class="nav-links" :class="{ 'show': isMenuOpen }">
        <router-link
          v-for="(link, index) in navLinks"
          :key="index"
          :to="link.path"
          class="nav-item"
        >
          {{ link.name }}
        </router-link>
      </nav>

      <!-- Menu Toggle for Mobile -->
      <button class="menu-toggle" @click="isMenuOpen = !isMenuOpen">
        <span v-if="!isMenuOpen">☰</span>
        <span v-else>✕</span>
      </button>
    </div>
  </header>
</template>

<script setup>
import { ref } from "vue";

// Navigation Links
const navLinks = [
  { name: "Home", path: "/" },
  { name: "Portfolio", path: "/portfolio" },
  { name: "Contact", path: "/contact" },
];

const isMenuOpen = ref(false);
</script>

<style scoped>
/* Basic styling */
.header {
  display: flex;
  align-items: center;
  height: 10vh;
  padding: 1rem 2rem;
  box-sizing: border-box;
  height: 10vh;

  background-color: rgb(255, 255, 255);
  color: #252525;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.header-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 99999;
}

.logo {
  font-size: 25px;
  cursor: pointer;
  text-decoration: none;
  color: #a13131;
  font-weight: bold;
}

.nav-links {
  display: flex;
  gap: 1.5rem;
}

.nav-item {
  color: #252525;
  text-decoration: none;
}

.nav-item:hover {
  color: #252525;
}

/* Mobile menu toggle button */
.menu-toggle {
  display: none;
  font-size: 1.5rem;
  background: none;
  border: none;
  color: #252525;
  cursor: pointer;
}

/* Responsive styles */
@media (max-width: 768px) {
  .nav-links {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 10vh;
    left: 0;
    width: 100%;
    background-color: #eee;
    padding: 1rem;
    gap: 1rem;
  }

  .nav-links.show {
    display: flex;
  }

  .menu-toggle {
    display: inline-block;
  }
}
</style>
